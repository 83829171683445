@font-face {
  font-family: "AudiType-ExtendedNormal";
  src: url("../fonts/AudiType-ExtendedNormal.ttf");
}

@font-face {
  font-family: "AudiType-ExtendedBold";
  src: url("../fonts/AudiType-ExtendedBold.ttf");
}

@font-face {
  font-family: "AudiTypeScreen-Normal";
  src: url("../fonts/AudiTypeScreen-Normal.ttf");
}

@font-face {
  font-family: "AudiTypeScreen-Bold";
  src: url("../fonts/AudiTypeScreen-Bold.ttf");
}

@font-face {
  font-family: "AudiTypeScreen-Light";
  src: url("../fonts/AudiTypeScreen-Light.ttf");
}

@media only screen and (min-width: 1200px) {
  .navbar {
    background-color: #000;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
  }
  .navbar img {
    width: 100px;
    max-width: 200px;
    margin-left: 20%;
    pointer-events: none;
  }
  .navbar .select {
    margin-left: 20%;
    margin-top: 1rem;
  }

  .navbar .select select {
    border: 2px solid #fff !important;
    background: #000;
    color: #fff;
    padding: 0.5rem 2rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: "AudiTypeScreen-Normal";
    font-size: 1rem;
    border-radius: 0px !important;
    background-image: url("../img/Vector_White.png") !important;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: top 50% right 5px;
  }
  .navbar .select select::-ms-expand {
    display: none;
  }
  header {
    background-image: url("../img/fondo-desk.jpg");
    padding: 2rem;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  header .spanish .texto p {
    font-family: "AudiType-ExtendedNormal";
    font-size: 60px !important;
    color: #fff;
  }
  .header .spanish .texto b {
    font-family: "AudiTypeScreen-Bold" !important;
  }
  .header .english .texto b {
    font-family: "AudiTypeScreen-Bold" !important;
  }
  header .english .texto {
    font-family: "AudiType-ExtendedNormal";
    font-size: 60px !important;
    color: #fff;
  }
  header .spanish {
    width: 28%;
    display: flex;
    flex-direction: column;
    margin-left: 20%;
  }
  header .english {
    width: 28%;
    display: flex;
    flex-direction: column;
    margin-left: 20%;
  }
  header .spanish .imagen,
  header .english .imagen {
    width: 50%;
  }
  header .spanish .imagen img,
  header .english .imagen img {
    margin-left: 0.2rem;
    cursor: pointer;
    height: 70px;
  }

  header .stores-desk {
    display: flex;
    flex-direction: row;
    margin-left: -1rem;
  }

  .body {
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding: 3rem 0rem;
    height: auto;
    margin-left: 20%;
    pointer-events: none;
  }

  .body .imagenAudi img {
    width: 200px;
    max-width: 300px;
    pointer-events: none;
  }

  .body .texto-body h1 {
    font-size: 40px;
    font-family: "AudiType-ExtendedNormal";
  }
  .body .texto-body {
    width: 50vw;
    padding: 0rem 1rem;
  }
  .d-none {
    display: none !important;
  }
  footer {
    display: flex;
    flex-direction: row;
    margin-left: 21%;
    height: auto;
  }
  footer .links-1,
  footer .links-2 {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0rem 4rem;
    margin-left: -4rem;
  }
  footer .links-1 h3,
  footer .links-2 h3 {
    font-family: "AudiTypeScreen-Bold";
    font-size: 1rem;
  }
  footer .esp,
  footer .eng {
    display: inline-flex;
    margin-bottom: 3rem;
    align-items: center;
    justify-content: center;
  }
  footer .links-1 ul,
  footer .links-2 ul {
    list-style: none;
    margin: 0 -2.5rem;
    text-decoration: none;
  }
  footer .links-1 ul li,
  footer .links-2 ul li {
    text-align: left;
    padding-bottom: 1rem;
    cursor: pointer;
    font-family: "AudiTypeScreen-Normal";
    font-size: 1rem;
  }
  footer .links-1 ul li a,
  footer .links-2 ul li a {
    text-align: left;
    font-family: "AudiTypeScreen-Normal";
    font-size: 1rem;
    text-decoration: none;
    color: #000;
  }
  footer .select-2 {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0rem 1.2rem;
    margin-top: 5%;
    margin-left: 2%;
  }

  footer .select-2 select {
    border: 2px solid #000 !important;
    background: #fff;
    color: #000;
    padding: 0.5rem !important;
    width: 152.8%;
    margin-bottom: 5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: "AudiTypeScreen-Normal";
    font-size: 1rem;
    border-radius: 0px !important;
    background-image: url("../img/black_arrow.png") !important;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: top 50% right 5px;
  }
  footer .select-2 select::-ms-expand {
    display: none;
  }
  /* Conditions component css */

  .terms,
  .privacy,
  .faq {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    height: auto;
    justify-content: center;
    margin-left: 19%;
  }
  .borde {
    border-bottom: 1px solid #000;
  }
  .terms .back img,
  .privacy .back img,
  .faq .back img {
    width: 25px;
    max-width: 35px;
  }
  .terms .text-terms,
  .privacy .text-privacy {
    width: 60%;
  }
  .terms .text-terms h4,
  .privacy .text-privacy h4 {
    font-family: "AudiTypeScreen-Bold";
    font-size: 20px;
  }
  .privacy .text-privacy h5 {
    font-family: "AudiTypeScreen-Normal";
  }
  .privacy .text-privacy .underline {
    text-decoration: underline;
  }
  .privacy .text-privacy li {
    font-family: "AudiTypeScreen-Normal";
  }
  .terms .text-terms li {
    font-family: "AudiTypeScreen-Normal";
  }
  .privacy .text-privacy ul li strong {
    font-family: "AudiTypeScreen-Bold";
  }

  .terms .text-terms p,
  .privacy .text-privacy p {
    font-family: "AudiTypeScreen-Normal";
    font-size: 16px;
    line-height: 20px;
  }
  /* Accordion Styles */
  .accordion {
    padding: 1rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .acordion h4 {
    font-family: "AudiTypeScreen-Bold";
    font-size: 20px;
  }
  .accordion .p1Regular {
    font-family: "AudiTypeScreen-Normal";
    font-size: 16px;
    padding: 0.3rem !important;
  }
  .accordion {
    border: none !important;
    border-bottom: 1px solid #000 !important;
    border-radius: 0px !important;
    width: 70% !important;
  }
  .acordion .vector {
    width: 2%;
    margin-left: auto;
    top: 17px;
    position: relative;
    right: 13px;
    z-index: 1;
  }
  .accordion .accordion-item .title {
    background-color: #fff !important;
    font-family: "AudiTypeScreen-Bold";
    font-size: 15px;
    padding: 0.3rem !important;
  }
  .accordion .accordion-item:last-child .title {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .accordion .accordion-item .title:after {
    content: "" !important;
  }
}
